import { CSSProperties, FC, PropsWithChildren, ReactNode, memo, useCallback, useMemo } from 'react'

import { Checkbox, Col, Flex, Row } from 'antd'

import { useColor } from '../app'
import { Avatar } from '../image/Avatar'
import { ListItemMeta } from '../list/ListItem'
import { Text, Title } from '../text'
import styles from './styles/cardcheckable.module.css'

export type CheckableCardProps = {
  checked?: boolean
  disabled?: boolean
  enableUncheck?: boolean
  onChange?: (checked: boolean) => void
  avatar?: SDK.Components.AvatarProps
  extra?: ReactNode | 'checkbox'
  title?: ReactNode
  description?: ReactNode
  bordered?: boolean
  style?: CSSProperties
  className?: string
  checkbox?: boolean
  checkTheme?: 'bordered' | 'background'
}

export const CheckableCard: FC<PropsWithChildren<CheckableCardProps>> = ({
  checked: checked = false,
  enableUncheck,
  onChange = () => {},
  disabled = false,
  extra = false,
  bordered = true,
  style,
  title,
  description,
  avatar,
  checkTheme = 'background',
}) => {
  const handleChange = useCallback(() => {
    if (disabled || (checked && !enableUncheck)) return
    onChange(!checked)
  }, [checked, disabled, enableUncheck, onChange])

  const { token, components } = useColor()

  const colors = useMemo(() => {
    const backgroundColor = checked && checkTheme === 'background' ? components.Radio.buttonCheckedBg : 'transparent'
    const borderColor = `1px solid ${bordered || checkTheme === 'bordered' ? (checked ? token.colorPrimary : token.colorBorder) : 'transparent'}`

    return { backgroundColor, borderColor }
  }, [bordered, checkTheme, checked, components.Radio.buttonCheckedBg, token.colorBorder, token.colorPrimary])

  return (
    <Row
      align={'middle'}
      justify={'start'}
      wrap={false}
      gutter={[16, 16]}
      style={{
        width: '100%',
        padding: 16,
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor: colors.backgroundColor,
        border: colors.borderColor,
        borderRadius: token.borderRadius,
        opacity: disabled ? 0.35 : 1,
        ...style,
      }}
      className={styles.pvCardCheckable}
      onClick={() => handleChange()}
    >
      {avatar && (
        <Col flex={'none'}>
          <Avatar size={48} {...avatar} text={avatar.text}>
            {avatar.text}
          </Avatar>
        </Col>
      )}
      <Col flex={'auto'}>
        <Row gutter={[16, 16]} wrap={false} align={'middle'}>
          <Col flex={'auto'}>
            <Flex gap={0} align={'start'} vertical>
              {typeof title === 'string' ? <Title level={5}>{title}</Title> : title}
              {typeof description === 'string' ? <Text>{description}</Text> : description}
            </Flex>
          </Col>
          <Col flex={'none'}>
            {extra &&
              (extra === 'checkbox' ? (
                <Checkbox disabled={disabled} checked={checked} style={{ pointerEvents: 'none' }} />
              ) : (
                extra
              ))}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
