import { type FC, lazy, useEffect } from 'react'
import {
  Outlet,
  RouteObject,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  useLocation,
} from 'react-router-dom'

import Result from '@peoplevine/sdk/components/result'
import { AuthProvider } from '@peoplevine/sdk/providers'
import { PaymentProvider } from '@peoplevine/sdk/providers/payment/Payment'

import {
  errorBoundary,
  guestPolicy,
  membersPolicy,
  menuPagePolicy,
  pathAliases,
  plusPlanPolicy,
  routeEntries,
  routeObject,
} from './decorators'
import { drawerRoutes, globalNetworkRoutes, guestRoutes, memberRoutes, sharedRoutes } from './routes'

const Empty = lazy(() => import('src/layouts/Empty'))
const Shared = lazy(() => import('src/layouts/Shared'))
const Unauthorized = lazy(() => import('src/layouts/Unauthorized'))
const Authorized = lazy(() => import('src/layouts/Authorized'))
const Drawer = lazy(() => import('src/layouts/Drawer'))
const DefaultLayout = lazy(() => import('src/layouts/Default'))

function createRoutesFromConfig(): RouteObject[] {
  return [
    {
      // Component: () => <PublicBannerView fullscreen />,
      Component: Empty,
      children: routeEntries(globalNetworkRoutes).map(routeObject),
    },
    {
      Component: Shared,
      secure: false,
      children: routeEntries(sharedRoutes).flatMap(pathAliases).map(routeObject),
    },
    {
      Component: Unauthorized,
      secure: false,
      children: routeEntries(guestRoutes).flatMap(pathAliases).map(routeObject),
    },
    {
      Component: Authorized,
      secure: true,
      children: [
        {
          Component: DefaultLayout,
          children: routeEntries(memberRoutes)
            .map((it) => ({ ...it, secured: true }))
            .flatMap(pathAliases)
            .map(plusPlanPolicy)
            .map(membersPolicy)
            .map(menuPagePolicy)
            .map(guestPolicy)
            .map(errorBoundary)
            .map(routeObject),
        },
        {
          Component: Drawer,
          membersOnly: false,
          children: routeEntries(drawerRoutes)
            .map((it) => ({ ...it, membersOnly: true, secured: true }))
            .flatMap(pathAliases)
            .map(guestPolicy)
            .map(errorBoundary)
            .map(routeObject),
        },
      ],
    },
    {
      path: '*',
      Component: Result.NotFound,
    },
  ].map(guestPolicy)
}

/**
 * Restores scroll position to the top of the page on route change.
 * @constructor
 */
function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [pathname])

  return null
}

const RootRoute: FC = () => {
  return (
    <AuthProvider>
      <PaymentProvider>
        <ScrollToTop />
        <Outlet />
      </PaymentProvider>
    </AuthProvider>
  )
}

// const createRouter = wrapCreateBrowserRouter(createBrowserRouter)

const routes = [{ Component: RootRoute, children: createRoutesFromConfig() }]
const router = createBrowserRouter(routes)
const RoutesProvider = () => <RouterProvider router={router} />
export default RoutesProvider
