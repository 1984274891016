import { Children, FC, PropsWithChildren, ReactNode, memo, useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { AutoComplete, Col, ListProps, Row, TableColumnProps } from 'antd'

import { useLoading } from '@data-client/hooks'
import { AsyncBoundary, useSuspense } from '@data-client/react'

import { PaginatedHeader } from 'src/datasource/api/pagination'

import { createApiResource } from '../../datasource/api/endpoint'
import { ApiEntity } from '../../datasource/api/entity'
import { Card } from '../card'
import { CardCarousel } from '../carousel'
import { Section } from '../layout'
import { usePageHeader } from '../page/Provider'
import { Result } from '../result'
import { useResponsive } from '../screen'

const MemorizedCarousel = memo(CardCarousel)

type PaginatorSkeletonProps = {
  skeleton?: ReactNode
  count?: number
}

export type InfiniteGridProps<T extends ApiEntity = ApiEntity> = {
  dataSource?: T[] | undefined
  onNextPage?: () => Promise<void>
  filters?: Record<string, any>
  scrollToTop?: boolean
  loading?: boolean
  className?: string
  children?: ReactNode
  header?: ReactNode
  onEmpty?: ReactNode
  CardComponent?: FC<Data.Source<T>>
  EmptyComponent?: FC
  columns?: TableColumnProps<T>
  onRowClick?: (item: T) => void
  gridSize?: number
  maxSpan?: boolean
  pagination?: Partial<API.Pagination>
  skeleton?: PaginatorSkeletonProps
}

const skeletonData = (size: number) =>
  new Array(size).fill(0).map((_, i) => ({
    pk: () => `${i}`,
  }))

function InfiniteGrid<T extends ApiEntity = ApiEntity>({
  onNextPage = () => Promise.resolve(),
  onEmpty = <Empty />,
  EmptyComponent = () => onEmpty,
  pagination = new PaginatedHeader(),
  dataSource: data = [],
  CardComponent = () => <Card loading={true} image={{ loading: true }} />,
  loading: initialLoading,
  gridSize,
  maxSpan,
  header,
}: InfiniteGridProps<T>) {
  const { gridSize: size, gridColSize } = useResponsive()
  const [loadMore, loadingMore] = useLoading(onNextPage, [onNextPage])
  const loading = initialLoading || loadingMore
  const { isMobile } = useResponsive()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && !loading && pagination?.hasNextPage) {
      loadMore()
    }
  }, [inView, loadMore, loading, pagination?.hasNextPage])

  const span = isMobile ? { span: 24 } : { span: 12 }

  return (
    <div>
      {!loading && data?.length === 0 ? (
        <EmptyComponent />
      ) : (
        <Section title={header}>
          {data?.length > 0 && (
            <DataGrid
              data={data}
              size={gridSize ?? size ?? pagination?.pageSize}
              EmptyComponent={EmptyComponent}
              CardComponent={CardComponent}
              span={maxSpan ? span : gridColSize}
            />
          )}
          <br ref={ref} />
          {loading ? (
            <DataGrid
              loading={loading}
              data={data}
              size={gridSize ?? size ?? pagination?.pageSize}
              CardComponent={() => <Card loading={loading} image={{ loading }} />}
              span={maxSpan ? span : gridColSize}
              EmptyComponent={EmptyComponent}
            />
          ) : null}
        </Section>
      )}
    </div>
  )
}

export type GridProps<T extends ApiEntity = ApiEntity> = Pick<ListProps<T>, 'grid' | 'renderItem' | 'className'> & {
  resource: Pick<ReturnType<typeof createApiResource<new () => T>>, 'infinitePagination' | 'paginatedList'>
  header?: string
  loading?: boolean
  params?: any
  EmptyComponent?: FC<Partial<SDK.Components.CardProps>>
  CardComponent?: FC<Partial<Data.Source<T> & Data.Loadable>>
  span?: SDK.Components.GridColSize
}

const Empty = () => {
  const { eventFilter } = usePageHeader()
  return (
    <div style={{ marginTop: eventFilter ? 112 : undefined }}>
      <Result.NoData
        title={'Your filters produced no results!'}
        subTitle={'Try adjusting or clearing your filters to display better results.'}
        name={''}
        back={false}
        logout={false}
      />
    </div>
  )
}

type DataGridProps<T extends ApiEntity = ApiEntity> = PropsWithChildren<
  Data.Source<T[]> &
    Data.Loadable & {
      size?: number
      EmptyComponent: FC
      CardComponent: FC<Partial<Data.Source<T>> & Data.Loadable>
      span?: SDK.Components.GridColSize
    }
>

const DataGrid = ({
  data = [],
  size: initialSize = data?.length,
  EmptyComponent = Empty,
  CardComponent = () => <Card loading={true} image={{ loading: true }} />,
  loading = false,
  span,
}: Partial<DataGridProps>) => {
  const { gridSize, gridColSize } = useResponsive()
  const size = gridSize
  span = span ?? gridColSize

  return (
    <Row gutter={[32, 32]}>
      {data?.map((data) => (
        <Col key={data.pk()} {...span}>
          <CardComponent data={data} loading={loading} />
        </Col>
      ))}

      {loading
        ? Children.map(
            skeletonData(size)?.map((_, index) => <CardComponent loading={loading} key={index + data?.length} />),
            (child, index) => (
              <Col key={index} {...span}>
                {child}
              </Col>
            ),
          )
        : !data ||
          (data.length === 0 && (
            <Col span={24} style={{ height: '100%' }}>
              <EmptyComponent />
            </Col>
          ))}
    </Row>
  )
}

function Grid<T extends ApiEntity = ApiEntity>({
  resource,
  params,
  loading = false,
  EmptyComponent = Empty,
  CardComponent = () => <Card loading image={{ loading: true }} />,
  children,
  span,
}: PropsWithChildren<GridProps<T>>) {
  const Component = memo(() => {
    const data = useSuspense(resource.paginatedList, { ...params })

    return (
      <DataGrid
        loading={loading}
        data={data.results as ApiEntity[]}
        EmptyComponent={EmptyComponent}
        CardComponent={CardComponent}
        span={span}
      >
        {children}
      </DataGrid>
    )
  })

  return (
    <AsyncBoundary fallback={<DataGrid loading={true} size={params?.pageSize} />}>
      <Component />
    </AsyncBoundary>
  )
}

function GridCarousel<T extends ApiEntity = ApiEntity>({
  resource,
  params,
  EmptyComponent,
  CardComponent = () => <Card loading image={{ loading: true }} />,
}: PropsWithChildren<GridProps<T>>) {
  const Component = memo(() => {
    const data = useSuspense(resource.paginatedList, { ...params })
    return data && data.results.length > 0 ? (
      <MemorizedCarousel size={3}>
        {data.results.map((item: T) => (
          <CardComponent key={item.id} data={item} />
        ))}
      </MemorizedCarousel>
    ) : EmptyComponent ? (
      <EmptyComponent />
    ) : null
  })

  return (
    <AsyncBoundary
      fallback={
        <DataGrid
          loading={true}
          span={{
            span: 24,
            md: 8,
          }}
        />
      }
    >
      <Component />
    </AsyncBoundary>
  )
}

export { DataGrid, Grid, GridCarousel, InfiniteGrid }
export default InfiniteGrid
