import { FC, useCallback, useEffect } from 'react'
import { useSet } from 'react-use'

import { List, Tag as Wrapped } from 'antd'
import { createStyles } from 'antd-style'

import Color from 'color'

import { Text } from 'src/components/text'

import IvyIcon from '../icon'

const useStyles = createStyles(({ token, css }: { token: any; css: any }) => ({
  default: css`
    background: ${Color(token.tagDefaultBg).fade(0.75).hexa()};
    color: ${token.colorTextSecondary};
    border: 1px solid ${token.tagDefaultBg};
    border-radius: 24px;
    padding: 4px 8px;
    margin-bottom: 0;
    max-width: fit-content;
  `,
  secondary: css`
    background: ${Color(token.tagDefaultBg).fade(0.75).hexa()};
    color: ${token.colorTextSecondary};
    border: 1px solid ${token.tagDefaultBg};
    border-radius: 24px;
    padding: 4px 8px;
    margin-bottom: 0;
    max-width: fit-content;
  `,
  info: css`
    background: ${Color(token.colorInfo).fade(0.75).hexa()};
    color: ${token.colorInfo};
    border: 1px solid ${token.colorInfo};
    border-radius: 24px;
    padding: 4px 8px;
    margin-bottom: 0;
    max-width: fit-content;
  `,
  warning: css`
    background: ${Color(token.colorWarning).fade(0.75).hexa()};
    color: ${token.colorWarning};
    border: 1px solid ${token.colorWarning};
    border-radius: 24px;
    padding: 4px 8px;
    margin-bottom: 0;
    max-width: fit-content;
  `,
  error: css`
    background: ${Color(token.colorError).fade(0.75).hexa()};
    color: ${token.colorError};
    border: 1px solid ${token.colorError};
    border-radius: 24px;
    padding: 4px 8px;
    margin-bottom: 0;
    max-width: fit-content;
  `,
  success: css`
    background: ${Color(token.colorPrimary).fade(0.75).hexa()};
    color: ${token.colorPrimary};
    border: 1px solid ${token.colorPrimary};
    border-radius: 24px;
    padding: 4px 8px;
    margin-bottom: 0;
    max-width: fit-content;
  `,
  primary: css`
    background: ${Color(token.colorPrimary).fade(0.75).hexa()};
    color: ${token.colorPrimary};
    border: 1px solid ${token.colorPrimary};
    border-radius: 24px;
    padding: 4px 8px;
    margin-bottom: 0;
    max-width: fit-content;
  `,
}))

const Tag: FC<SDK.Components.TagProps> = ({
  shape = 'asymmetric',
  type = 'default',
  size = 'small',
  icon,
  checkable = false,
  checked,
  defaultChecked = false,
  disabled,
  onCheck,
  bordered = true,
  children = '',
  style,
  ...baseProps
}) => {
  const { styles } = useStyles()

  return checkable ? (
    <Wrapped.CheckableTag
      checked={checked ?? defaultChecked}
      onChange={(checked) => {
        if (!checkable) return
        onCheck && onCheck(checked)
      }}
      className={checked ? styles.primary : styles.default}
      {...baseProps}
    >
      <Text
        size={size}
        font={{ family: 'secondary', size: 11, weight: 700 }}
        style={{ color: 'inherit', textTransform: 'uppercase' }}
        uppercased
      >
        {children}
      </Text>
    </Wrapped.CheckableTag>
  ) : (
    <Wrapped
      defaultChecked={defaultChecked}
      bordered={bordered}
      icon={icon && <IvyIcon type={icon} />}
      className={styles[type] ?? ''}
      {...baseProps}
    >
      <Text size={size} font={{ family: 'secondary', size: 10, weight: 700 }} color={'inherit'} uppercased>
        {children}
      </Text>
    </Wrapped>
  )
}

const Tags: FC<SDK.Components.TagsProps> = ({
  shape = 'asymmetric',
  type = 'default',
  size = 'small',
  checked: defaultChecked,
  checkable,
  onChange = () => {},
  onCheck = () => {},
  multi = true,
  data = [],
}) => {
  const [allChecked, { has, add, toggle, clear }] = useSet<string>(
    new Set(defaultChecked ? (Array.isArray(defaultChecked) ? defaultChecked.map(String) : [`${defaultChecked}`]) : []),
  )

  const handleCheck = useCallback(
    (id: Data.ID) => {
      if (multi) {
        toggle(`${id}`)
      } else {
        const checked = has(`${id}`)
        clear()
        if (!checked) {
          add(`${id}`)
        }
        onCheck(id, !checked)
      }
    },
    [multi, has, toggle, add, clear, onCheck],
  )

  useEffect(() => {
    onChange(allChecked)
  }, [allChecked, onChange])

  return (
    <List
      rowKey={'id'}
      split={false}
      grid={{ gutter: [8, 8] }}
      bordered={false}
      dataSource={data}
      renderItem={(tag) => {
        return (
          <List.Item style={{ marginBottom: 0 }}>
            <Tag
              type={type}
              key={tag.id}
              size={size}
              shape={shape}
              checked={has(`${tag.id}`)}
              checkable={checkable}
              onCheck={() => handleCheck(tag.id)}
              style={{ overflowWrap: 'anywhere', whiteSpace: 'normal' }}
            >
              {tag.title}
            </Tag>
          </List.Item>
        )
      }}
    />
  )
}

export { Tag, Tags }
