import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react'

const urls = ['localhost', window.location.origin]
import.meta.env.REACT_APP_PUBLIC_URL && urls.push(import.meta.env.REACT_APP_PUBLIC_URL.replace('https://', ''))
import.meta.env.REACT_APP_API_URL && urls.push(import.meta.env.REACT_APP_API_URL.replace('https://', ''))

function initSentry() {
  return init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    allowUrls: urls,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: true,
  })
}

export { initSentry }
