import {
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { Form as AbstractForm, FormItemProps as AntFormItemProps, FormRule } from 'antd'

import { FieldType } from '../../datasource/survey'
import Text from '..//text/Text'
import { useColor } from '../app'
import { Button } from '../button/Button'
import { DatePicker } from './DatePicker'
import { Form } from './Form'
import { InputNumber } from './InputNumber'
import OneTimeCode from './OneTimeCode'
import { TimePicker } from './TimePicker'
import './index.css'
import RadioGroup from './radio/RadioGroup'
import { RadioSelect } from './radio/RadioSelect'
import styles from './styles/label.module.scss'

type FormItemProps<T = any> = {
  type?: FieldType
  label?: ReactNode
  placeholder?: string
  help?: ReactNode
  strong?: boolean
  size?: number
  uppercase?: boolean
  floating?: boolean
} & AntFormItemProps<T>

const Label: FC<
  PropsWithChildren<{
    hasTooltip?: boolean
    form?: string
    strong?: boolean
    size?: number
    uppercase?: boolean
    error?: boolean
  }>
> = ({ hasTooltip, strong, size, uppercase, error = false, children }) => {
  const { token, components } = useColor()
  return typeof children === 'string' ? (
    <Text
      uppercased={uppercase}
      translate={'yes'}
      type={'secondary'}
      font={{ size: size ? size : components.Form?.labelFontSize, weight: strong ? 600 : 400, family: 'secondary' }}
      style={{
        width: '100%',
        display: 'inline-block',
        padding: `0 ${hasTooltip ? 32 : 4}px 0 4px`,
        marginRight: hasTooltip ? -28 : undefined,
        marginLeft: -2,
        lineHeight: 1.3,
        backgroundColor: 'transparent',
        borderRadius: token.borderRadius,
        color: error ? token.colorError : components.Form?.labelColor,
      }}
    >
      {children}
    </Text>
  ) : (
    children
  )
}

const HelpText: FC<{ helperText: string | undefined }> = ({ helperText }) => {
  const { token, components, color } = useColor()
  return (
    helperText && (
      <Text
        translate={'yes'}
        type={'secondary'}
        font={{ size: components.Form?.labelFontSize, weight: 400 }}
        style={{
          width: '100%',
          padding: '6px 0 0 0',
          backgroundColor: color(token.colorBgLayout)?.hex(),
        }}
      >
        {helperText}
      </Text>
    )
  )
}

const FloatingItem: FC<Omit<FormItemProps, 'floating'>> = ({
  type,
  label,
  tooltip,
  placeholder,
  labelCol,
  required,
  strong,
  size,
  uppercase,
  help = '',
  rules,
  children,
  ...props
}) => {
  const value = AbstractForm.useWatch(props.name)
  const { token, components, resolveUnit } = useColor()
  const hasValue = useMemo(() => !!value, [value])
  const [active, setActive] = useState(hasValue)

  const floatingVariables = useMemo(() => {
    const verticalPadding = components.Input.paddingBlock
    const inputLineHeight = token.lineHeight * components.Input.inputFontSize
    const labelLineHeight = token.lineHeight * components.Form.labelFontSize
    const activeYPosition = verticalPadding - inputLineHeight / 2
    const activeSpace = 4
    return {
      defaultLabelTranslate: `translateY(${resolveUnit(verticalPadding)}) scale(1)`,
      activeLabelTranslate: `translateY(${resolveUnit(activeYPosition)}) scale(${components.Form.labelFontSize / components.Input.inputFontSize})`,
      inputPaddingTop: resolveUnit(labelLineHeight + activeSpace),
      inputPaddingBottom: resolveUnit(verticalPadding * 2 - labelLineHeight - activeYPosition),
    }
  }, [
    components.Input.paddingBlock,
    components.Input.inputFontSize,
    components.Form.labelFontSize,
    token.lineHeight,
    resolveUnit,
  ])

  const handleChange = useCallback(
    (focused: boolean) => {
      setActive(hasValue ? hasValue : focused)
    },
    [setActive, hasValue],
  )

  const renderChildren = useMemo(() => {
    if (isValidElement(children)) {
      return cloneElement(children as ReactElement, {
        style: {
          paddingTop: floatingVariables.inputPaddingTop,
          paddingBottom: floatingVariables.inputPaddingBottom,
        },
        onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
          handleChange(true)
          if (children.props.onFocus) {
            children.props.onFocus(e)
          }
        },
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
          handleChange(false)
          if (children.props.onBlur) {
            children.props.onBlur(e)
          }
        },
      })
    }
    return children
  }, [children, handleChange, floatingVariables])

  return (
    <AbstractForm.Item
      data-cy={props.name}
      style={{ width: '100%', position: 'relative', margin: 0, marginBottom: 12 }}
      labelCol={{
        className: styles.floatingLabelWrapper,
        style: {
          transform: active ? floatingVariables.activeLabelTranslate : floatingVariables.defaultLabelTranslate,
          opacity: active ? 0.8 : 1,
          left: resolveUnit(components.Input.paddingInline - 1),
        },
      }}
      wrapperCol={{
        span: 24,
      }}
      label={
        <Label size={size} uppercase={uppercase} strong={strong} hasTooltip={!!tooltip}>
          {label}
        </Label>
      }
      tooltip={tooltip}
      required={false} // Required set to false to hide the asterisk on floating label
      help={help && typeof help === 'string' ? <HelpText helperText={help} /> : help}
      rules={[...(rules ? rules : [{ required: required, message: 'This field is required' }])]}
      {...props}
    >
      {renderChildren}
    </AbstractForm.Item>
  )
}

const Item: FC<FormItemProps> = ({ floating = false, label, ...props }) => {
  if (floating && !!label) return <FloatingItem label={label} {...props} />

  const { tooltip, required, strong, size, uppercase, help, rules, ...rest } = props

  return (
    <AbstractForm.Item
      data-cy={props.name}
      style={{ width: '100%', position: 'relative', margin: 0 }}
      labelCol={{
        style: !!label ? { padding: '0 0 4px' } : { display: 'none' },
      }}
      wrapperCol={{
        span: 24,
      }}
      label={
        <Label size={size} uppercase={uppercase} strong={strong} hasTooltip={!!tooltip}>
          {label}
        </Label>
      }
      tooltip={tooltip}
      required={floating ? false : required}
      help={help && typeof help === 'string' ? <HelpText helperText={help} /> : help}
      rules={[...(rules ? rules : [{ required: required, message: 'This field is required' }])]}
      {...rest}
    />
  )
}

const List = AbstractForm.List

const useForm = AbstractForm.useForm

export { Switch } from './Switch'

export {
  Button,
  DatePicker,
  Form,
  HelpText,
  InputNumber,
  Item,
  Label,
  List,
  OneTimeCode,
  RadioGroup,
  RadioSelect,
  TimePicker,
  Form as default,
  useForm,
  type FormItemProps,
  type AntFormItemProps as FormProps,
  type FormRule,
}
