import { Entity } from '@data-client/rest'

import dayjs from '../services/dayjs'
import { BaseAddress } from './address'
import { ApiEntity } from './api/entity'
import { Category } from './category'
import { DayOfWeek } from './intl'
import { ImageEntity } from './media'

export type BusinessType = 'commerce' | 'business' | 'restaurant'

export type DirectoryDetail = {
  content: string
  header: string
}
export type BusinessSocial = {
  facebook?: string
  twitter?: string
  pinterest?: string
  linkedIn?: string
  flickr?: string
  vimeo?: string
  youtube?: string
}

export class BusinessHours extends Entity {
  sunday?: string = 'closed'
  monday?: string = 'closed'
  tuesday?: string = 'closed'
  wednesday?: string = 'closed'
  thursday?: string = 'closed'
  friday?: string = 'closed'
  saturday?: string = 'closed'

  get keyValue(): Data.Entry[] {
    const days = new Array(7).fill(0).map((_, i) => dayjs().day(i))
    return days.map((day) => {
      const normalizedDay = day.locale('en').format('dddd').toLowerCase()
      return {
        key: day.format('dddd'),
        value: this[normalizedDay] ? this[normalizedDay] : 'Closed',
      }
    })
  }

  get hasHours(): boolean {
    return Object.keys(this).filter((key) => key !== 'id' && this[key] !== '').length > 0
  }

  get today(): string | null {
    const dayOfWeek = dayjs().locale('en').format('dddd').toLowerCase()
    const today = this[dayOfWeek]
    return today !== '' ? today : 'Closed'
  }

  get closesToday(): string | undefined {
    if (this.today === null) return undefined
    const hour_split = this.today.split('-')
    if (hour_split.length < 2) return undefined
    const closes_time = hour_split[1].trim()

    const timeRegex = /^(\d{1,2})(?::(\d{2}))?\s*([AaPp][Mm])$/;
    const match = closes_time.match(timeRegex);
    if (!match) return closes_time

    const hour = match[1];
    const minute = match[2] || '00';
    const period = match[3].toUpperCase();

    return `${hour}:${minute} ${period}`;
  }

  get opensNext() {
    const today = dayjs().locale('en').format('dddd').toLowerCase() as DayOfWeek
    const tomorrow = dayjs().locale('en').add(1, 'day').format('dddd').toLowerCase()
    let day = dayjs()
    let opensAt = ''
    let count = 0

    while (count < 6) {
      day = day.add(1, 'day')
      let dayOfWeek = day.locale('en').format('dddd').toLowerCase()
      const openTime = this.openTime(dayOfWeek as DayOfWeek)
      if (openTime && openTime !== 'closed') {
        if (dayOfWeek === today) {
          dayOfWeek = 'today'
        } else if (dayOfWeek === tomorrow) {
          dayOfWeek = 'tomorrow'
        }
        opensAt = `${dayOfWeek} at ${openTime}`
        break
      }
      count++
    }
    return opensAt !== '' ? opensAt : null
  }

  pk(parent?: BusinessLocation, key?: Data.ID): string {
    return `${parent?.businessId}-${key}`
  }

  openTime(day: DayOfWeek) {
    const times = this[day]
    if (!times) return
    const hour_split = times.split('-')
    return hour_split[0].trim()
  }

  closeTime(day: DayOfWeek) {
    const times = this[day]
    if (!times) return
    const hour_split = day.split('-')
    return hour_split.length > 1 ? hour_split[1] : undefined
  }

  isOpen(timezoneOffset: number): boolean | undefined {
    if (!this.closesToday) return undefined
    const businessLocal = dayjs.tz().utcOffset(timezoneOffset)
    const businessCloseLocal = dayjs(`${businessLocal.format('YYYY-MM-DD')} ${this.closesToday}`, 'YYYY-MM-DD h:mm A')
    if (businessCloseLocal.format('A') === 'AM') {
      businessCloseLocal.add(1, 'day')
    }
    return businessLocal.isBefore(businessCloseLocal)
  }
}

export class BusinessContact extends Entity {
  readonly id: number = 0
  readonly businessId: number = 0
  readonly firstName: string = ''
  readonly lastName: string = ''
  readonly middleName: string = ''
  readonly phoneNumber: string = ''
  readonly mobileNumber: string = ''
  readonly email: string = ''
  readonly profilePhoto: string = ''

  pk(): string {
    return `${this.id}`
  }
}

export class BusinessLocation extends ApiEntity {
  static schema = {
    address: BaseAddress,
    hours: BusinessHours,
  }
  readonly businessId: number = 0
  readonly name: string = ''
  readonly email: string = ''
  readonly website?: string
  readonly phoneNumber: string = ''
  readonly tollFreeNumber: string = ''
  readonly latitude: string = ''
  readonly longitude: string = ''
  readonly hours?: BusinessHours = BusinessHours.fromJS()
  readonly address: BaseAddress = BaseAddress.fromJS()
}

export type BusinessQuery = {
  categoryId?: Data.ID
  search?: string
  group?: string
  orderBy?: string
} & API.PaginationParams


class BusinessEntity extends ApiEntity implements Data.Named, Data.SingleCategory, Data.Imaginated {
  static schema = {
    category: Category,
    // address: BaseAddress,
    contacts: [BusinessContact],
    media: [ImageEntity],
    hours: BusinessHours,
    locations: [BusinessLocation],
  }

  readonly hasProducts: boolean = false
  readonly categoryId: Data.ID = ''
  readonly category: Category = Category.fromJS()
  readonly address?: BaseAddress = BaseAddress.fromJS()
  readonly name: string = ''
  readonly slogan?: string
  readonly website: string = ''
  readonly about: string = ''
  readonly brief: string = ''
  readonly email: string = ''
  readonly phoneNumber: string = ''
  readonly type: BusinessType = 'business'
  readonly banner: string
  readonly graphic: string
  readonly surveyId: Data.ID = 0
  readonly contacts?: BusinessContact[]
  readonly media?: ImageEntity[]
  readonly details: DirectoryDetail[] = []
  readonly locations?: BusinessLocation[] = []
  readonly hasSchedulers: boolean = false
  readonly featured: boolean = false


  get hoursByLocation(): BusinessHours | undefined {
    if (!this.locations || this.locations.length === 0) return undefined
    const location = this.locations.find((l) => l.hours)
    if (!location) return BusinessHours.fromJS()
    return BusinessHours.fromJS(location.hours)
  }

  get categories(): Category[] {
    return [this.category]
  }

  get image(): string {
    return this.graphic ? this.graphic : `/assets/images/placeholders/directory-company.svg`
  }

  get images(): string[] {
    return this.media && this.media.length > 0
      ? this.media.filter((m) => m.type === 'image')?.map((m) => m.url)
      : [this.image]
  }

  get banners(): string[] {
    return this.media && this.media.length > 0
      ? this.media.filter((m) => m.type === 'image')?.map((m) => m.url)
      : [this.banner]
  }

  set(hours: BusinessHours) { }
}

export { BusinessEntity }
