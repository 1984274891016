import { CSSProperties, FC } from 'react'

import { Flex } from 'antd'

import { useColor } from '../app'
import { Button } from '../button/Button'
import Icon from '../icon'

const DrawerCloseButton: FC<{
  drawerPlacement: 'right' | 'fullscreen' | 'left'
  onClick?: VoidFunction
  loading?: boolean
  customStyle?: CSSProperties
}> = ({ onClick, drawerPlacement, loading, customStyle = {} }) => {
  const { token, components } = useColor()
  const style: CSSProperties =
    drawerPlacement === 'right'
      ? {
          position: 'absolute',
          top: 32,
          left: -24,
          zIndex: 1001,
          ...customStyle,
        }
      : drawerPlacement === 'left'
        ? {
            position: 'absolute',
            top: 40,
            right: -24,
            zIndex: 1001,
            ...customStyle,
          }
        : {
            position: 'absolute',
            top: 16,
            right: 24,
            height: 48,
            width: 48,
            textAlign: 'center',
            fontSize: 16,
            zIndex: 1111,
            ...customStyle,
          }

  return (
    <Flex
      align={'center'}
      justify={'center'}
      style={{
        borderRadius: token.size * 3,
        border: `1px solid ${token.colorBorder}`,

        width: token.size * 3,
        height: token.size * 3,
        background: components.Layout?.bodyBg,
        ...style,
      }}
    >
      <Icon data-cy={'DrawerCloseButton'} type={'symbol/close'} onClick={onClick} />
    </Flex>
  )
}

export { DrawerCloseButton }
