import { FC, useEffect } from 'react'

function createLinkElement(url: string): Element {
  const element = document.createElement('link')
  element.rel = 'stylesheet'
  element.href = url
  return element
}

function resolveFamily(name = '') {
  return name
    .replace('sans-serif', '')
    .replace('serif', '')
    .replaceAll('"', '')
    .replaceAll("'", '')
    .split(',')[0]
    .trim()
}

function resolveUrl(name: string) {
  return `https://fonts.googleapis.com/css2?family=${name}:wght@400;500;600;700;800;900&display=swap`
}

const FontGenerator = (fonts: string[]) => fonts.map(resolveFamily).map(resolveUrl)

// Updated font loader to apply custom URLs
const FontLoader: FC<{ fonts: string[]; urls?: string[] }> = ({ fonts = [], urls = [] }) => {
  useEffect(() => {
    const elements = [...FontGenerator(fonts), ...urls?.filter((u) => !u?.includes('google'))]
      ?.map(createLinkElement)
      ?.map((element) => document.head.appendChild(element))
    return () => {
      elements?.map((element) => document.head.removeChild(element))
    }
  }, [fonts, urls])

  return null
}

export { FontGenerator, FontLoader }
