import { FC, PropsWithChildren, type SVGProps, createContext, memo, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { AsyncBoundary, useSuspense } from '@data-client/react'

import camelcaseKeys from 'camelcase-keys'
import classNames from 'classnames'
import kebabcase from 'lodash.kebabcase'

import { Spinner } from '../components/loader'
import { Result } from '../components/result'
import defaultConfig from '../configs'
import { ConfigResource, PageConfig, PortalConfig } from '../datasource/config'

const Context = createContext<PortalConfig>(defaultConfig)

const configs: Record<string, any> = import.meta.glob('../configs/*.json', {
  eager: true,
  import: 'default',
})

function getLocalConfig(customerId: string) {
  const data = camelcaseKeys(configs[`../configs/${customerId}.json`], { deep: true, exclude: ['messages'] })
  return PortalConfig.fromJS({
    ...data,
    theme: Object.entries(data.theme)
      .map(([key, value]) => {
        return { [`@${kebabcase(key)}`]: value }
      })
      .reduce((acc, it) => ({ ...acc, ...it }), {}),
  })
}

const ConfigDataProvider: FC<PropsWithChildren<Data.Source<PortalConfig>>> = ({ data, children }) => {
  return <Context.Provider value={data}>{children}</Context.Provider>
}

const Provider: FC<PropsWithChildren<{ configId?: string }>> = memo(({ configId , children }) => {
  // Possibly pulling config from local storage to run dev mode (switching between portals)
  // const config = ls.get<PortalConfig>('pv.dev.config')
  let data = useSuspense(ConfigResource.handshake)
  if (!data) return <Result.ConfigurationError />

  if (configId) {
    return <ConfigDataProvider data={getLocalConfig(configId)}>{children}</ConfigDataProvider>
  } else {
    return <ConfigDataProvider data={PortalConfig.fromJS(data)}>{children}</ConfigDataProvider>
  }
})

export const useConfig = () => useContext(Context)

export type IntlFormatter = {
  currency: {
    (value: number): string
    (value: number | bigint): string
  }
  message: (id: string, defaultMessage: string) => string
}

export const useIntlFormatter: () => IntlFormatter = () => {
  const { formatMessage, formatNumber } = useIntl()
  const {
    intl: { currency },
  } = useConfig()
  return {
    currency: (value: number | bigint) => formatNumber(value, { format: 'money', currency: currency }),
    message: (id: string, defaultMessage: string) => formatMessage({ id, defaultMessage: defaultMessage }),
  }
}

type MenuConfigProps = {
  pages: PageConfig[]
  pageByUri: (uri: string, getBase?: boolean) => PageConfig | undefined
  pageNameByUri: (uri: string, fallback?: string) => string
}

export const useMenuConfig: () => MenuConfigProps = () => {
  const portal = useConfig()
  const pages = useMemo(
    () => portal.pages.filter((page) => page.enabled).sort((a, b) => a.order - b.order),
    [portal.pages],
  )

  function pageByUri(uri = '', getBase = false) {
    let pathname = uri
    if (getBase) {
      pathname = (uri.startsWith('/') ? uri.slice(1) : uri).split('/')[0]
    }
    pathname = !pathname.startsWith('/') ? `/${pathname}` : pathname
    return pages.find((it) => it.uri === `${pathname}`)
  }

  const pageNameByUri = (uri = '', fallback?: string) => pageByUri(uri)?.name ?? fallback ?? ''
  return { pages, pageNameByUri, pageByUri }
}

export const withPrefix: (...names: string[]) => string = (...names) => {
  const key = 'pv'
  return classNames(
    names.filter((value) => !!value.trim()).map((name) => (!name.startsWith(key) ? `${key}-${name} ` : name)),
  ).trim()
}

const ConfigProvider: FC<PropsWithChildren<{ configId?: string }>> = ({ configId, children }) => (
  <AsyncBoundary fallback={<Spinner fullscreen />}>
    <Provider configId={configId}>{children}</Provider>
  </AsyncBoundary>
)

export { ConfigProvider, ConfigDataProvider, Context }
