import { type FC, PropsWithChildren, createContext, useContext, useMemo } from 'react'

import { Elements, useStripe } from '@stripe/react-stripe-js'
import { Appearance, loadStripe } from '@stripe/stripe-js'

import { useColor } from '../../../components/app'
import { Spinner } from '../../../components/loader'
import { useConfig } from '../../ConfigProvider'
import { FontGenerator } from '../../FontProvider'
import { AddPaymentMethodForm } from './AddPaymentMethodForm'

type StripeContextInterface = {} & SDK.Components.PaymentProcessorProps

const StripeContext = createContext<StripeContextInterface>({
  token: '',
  id: '',
  currency: '',
})

const useStripeContext: () => StripeContextInterface = () => {
  return useContext(StripeContext)
}

type StripeProviderContext = {
  processorId: Data.ID
  publishableKey: string
}

const StripeComponent: FC<PropsWithChildren<StripeProviderContext>> = ({ processorId, children }) => {
  const stripe = useStripe()
  return stripe ? children : <Spinner fullscreen />
}

const StripeProvider: SDK.Components.PaymentProcessorContext = ({ id, token, acct, children, ...params }) => {
  const { token: colorToken } = useColor()
  const {
    intl: { currency },
  } = useConfig()
  const stripe = useMemo(() => loadStripe(token), [token])
  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: colorToken.colorPrimary,
      colorBackground: colorToken.colorBgContainer,
      colorText: colorToken.colorText,
      colorDanger: colorToken.colorError,
      borderRadius: `${colorToken.borderRadius}px`,
      fontLineHeight: colorToken.lineHeight,
      fontFamily: colorToken.fontFamilySecondary,
      fontWeightBold: '600',
      fontWeightNormal: '400',
      fontSizeBase: `${colorToken.fontSize}px`,
      fontSizeSm: `${colorToken.fontSizeSM}px`,
      fontSizeLg: `${colorToken.fontSize}px`,
      focusBoxShadow: 'none',
    },
    rules: {
      '#Field-numberInput': {
        padding: '20px',
      },
    },
    labels: 'above',
  }
  const fonts = FontGenerator([colorToken.fontFamily, colorToken.fontFamilySecondary])
  return (
    <StripeContext.Provider value={{ id, token, ...params }}>
      <Elements
        stripe={stripe}
        options={{
          onBehalfOf: acct,
          currency: currency.toLowerCase(),
          mode: 'setup',
          paymentMethodTypes: ['card'],
          appearance: appearance,
          fonts: fonts.map((f) => ({
            cssSrc: f,
          })),
        }}
      >
        <StripeComponent processorId={id} publishableKey={token}>
          {children}
        </StripeComponent>
      </Elements>
    </StripeContext.Provider>
  )
}

StripeProvider.AddPaymentMethodForm = AddPaymentMethodForm

export { useStripeContext }
export default StripeProvider
