import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { createSignalRContext } from 'react-signalr/signalr'

import { HttpTransportType } from '@microsoft/signalr'

import { useAuth, useConfig, useNativeApp } from '@peoplevine/sdk/providers'
import { fetchToken } from '@peoplevine/sdk/services/tokens'

import { useAccount } from 'src/components/account/Provider'

const SignalRContext = createSignalRContext()

type AnalyticsPlatform = 'mobileApp' | 'mobileWeb' | 'desktop' | 'unknown'

type Activity = {
  company_id: number
  domain_id: number
  customer_id: number
  session_id: string
  referrer?: string
  platform: AnalyticsPlatform
  path?: string
  user_agent: string
}

type AnalyticsContextProps = {}

const defaultValues: AnalyticsContextProps = {}

const AnalyticsContext = createContext<AnalyticsContextProps>(defaultValues)

const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { domainId } = useConfig()
  const [connectionUrl, setConnectionUrl] = useState<string>('')
  const { customer } = useAccount()
  const { sessionId } = useAuth()
  const location = useLocation()

  const [connected, setConnected] = useState<boolean>()
  const { isNativeApp } = useNativeApp()

  const tokenFactory = useCallback(async () => {
    return (await fetchToken('customer')) ?? ''
  }, [fetchToken])

  const init = () => {
    fetch('/client-info')
      .then((res) => res.json())
      .then((res) => {
        setConnectionUrl(res.ws)
      })
  }

  const getDeviceType = useCallback((): AnalyticsPlatform => {
    if (isNativeApp) return 'mobileApp'
    if (!navigator.userAgent) return 'unknown'
    if (/mobile/i.test(navigator.userAgent)) {
      return 'mobileWeb'
    } else if (/desktop|windows|mac os|linux/i.test(navigator.userAgent)) {
      return 'desktop'
    }
    return 'unknown'
  }, [isNativeApp])

  const baseParams = useMemo(
    () =>
      ({
        domain_id: domainId,
        session_id: sessionId,
        referrer: document.referrer,
        platform: getDeviceType(),
        user_agent: navigator.userAgent,
      }) as Activity,
    [domainId, customer.id, sessionId],
  )

  const handleLog = useCallback(
    (log: Activity) => {
      SignalRContext.connection?.send('activity', log)
    },
    [baseParams],
  )

  useEffect(() => {
    if (location && baseParams && connected) {
      handleLog({
        ...baseParams,
        path: location.pathname,
      })
    }
  }, [location, baseParams, connected])

  useEffect(() => {
    init()
  }, [])

  return (
    <SignalRContext.Provider
      // connectEnabled={connectionUrl !== ''}
      connectEnabled={false}
      onOpen={() => setConnected(true)}
      onBeforeClose={() => setConnected(false)}
      withCredentials={true}
      transport={HttpTransportType.WebSockets}
      skipNegotiation={true}
      accessTokenFactory={tokenFactory}
      url={connectionUrl}
    >
      <AnalyticsContext.Provider value={{}}>{children}</AnalyticsContext.Provider>
    </SignalRContext.Provider>
  )
}

const useAnalytics: () => AnalyticsContextProps = () => useContext(AnalyticsContext)

export { AnalyticsProvider, useAnalytics }
