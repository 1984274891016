import { FC, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useConfig } from '../../providers'
import { Layout } from '../layout'
import { usePageHeader } from './Provider'

const PageComponent: FC<Partial<SDK.Components.PageProps>> = ({ responsive, style, children }) => {
  return (
    <Layout style={{ maxWidth: !responsive ? 1320 : undefined, background: 'inherit', ...style }}>{children}</Layout>
  )
}

const Page: FC<Partial<SDK.Components.PageProps>> = ({ children, title, extra, eventFilter, ...props }) => {
  const { pathname } = useLocation()
  const { pageByUri } = useConfig()
  const pageTitle = useMemo(() => {
    return title || pageByUri(pathname)?.name
  }, [title, pathname, pageByUri])

  const { setPageTitle, setExtraNode, setEventFilter } = usePageHeader()

  useEffect(() => {
    if (pageTitle) setPageTitle(pageTitle)
    setExtraNode(extra)
    setEventFilter(eventFilter)
    return () => {}
  }, [extra, pageTitle, eventFilter, setExtraNode, setEventFilter, setPageTitle])

  return <PageComponent {...props}>{children}</PageComponent>
}

export default Page
