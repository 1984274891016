import {
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { useMenuConfig } from '../../providers'

import PageHeaderProps = SDK.Components.PageHeaderProps

const Context = createContext<
  SDK.Components.PageHeaderProps & {
    setPageTitle: Dispatch<SetStateAction<string>>
    setExtraNode: Dispatch<SetStateAction<ReactNode>>
    setEventFilter: Dispatch<SetStateAction<ReactNode>>
  }
>({
  title: '',
  breadcrumbs: {},
  extra: null,
  eventFilter: null,
  setPageTitle: () => {},
  setExtraNode: () => {},
  setEventFilter: () => {},
})

const PageProvider: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation()
  const { pageByUri } = useMenuConfig()
  const homepageBreadcrumb = useMemo(
    () => ({
      title: pageByUri('/')?.name ?? 'My Club',
      path: '/',
    }),
    [pageByUri],
  )

  const [state, setState] = useState<PageHeaderProps>({
    title: '',
    breadcrumbs: { ...homepageBreadcrumb },
  })

  const setPageTitle = useCallback(
    (title: string) => {
      title && title !== state.title && setState((prevState) => ({ ...prevState, title }))
    },
    [state.title],
  )

  const setExtraNode = useCallback((extraNode: ReactNode) => {
    setState((prevState) => ({ ...prevState, extra: extraNode }))
  }, [])

  const setEventFilter = useCallback((extraNode: ReactNode) => {
    setState((prevState) => ({ ...prevState, eventFilter: extraNode }))
  }, [])

  const pageTitle = useMemo(() => {
    const pageName = pageByUri(pathname)
    return pageName ? pageName.name : state.title
  }, [pathname, state.title, pageByUri])

  const breadcrumbs = useMemo(() => {
    const breadcrumbItems: {
      title: string
      path?: string
    }[] = [homepageBreadcrumb]

    const mainPage = pageByUri(pathname, true)
    if (mainPage) {
      breadcrumbItems.push({
        title: mainPage.name,
        path: mainPage.uri,
      })
    }

    if (mainPage?.name !== pageTitle) {
      breadcrumbItems.push({
        title: pageTitle,
      })
    }

    return breadcrumbItems.reduce((acc, { path, title }) => ({ ...acc, [title]: path }), {})
  }, [homepageBreadcrumb, pageByUri, pageTitle, pathname])

  return (
    <Context.Provider
      value={{
        setPageTitle,
        setExtraNode,
        setEventFilter,
        title: pageTitle,
        breadcrumbs,
        extra: state.extra,
        eventFilter: state.eventFilter,
      }}
    >
      {children}
    </Context.Provider>
  )
}

const usePageHeader = () => useContext(Context)

export { Context, PageProvider, usePageHeader }
