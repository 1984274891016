import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

enum BreakpointWidth {
  XS = 375,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1600,
}

function spanByBreakpoint(width: number, size: SDK.Components.ScreenSize) {
  const siderWidth = size === 'xs' || size === 'sm' ? 0 : size === 'md' || size === 'lg' ? 112 : 280
  return Math.floor((width - siderWidth - 16 * 2) / (16 * 24))
}

function useDebouncedWindowSize(delay = 100) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  let timeoutId: NodeJS.Timeout
  useEffect(() => {
    const handleResize = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }, delay)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('resize', handleResize)
    }
  }, [delay])

  return windowSize
}

type Breakpoint = SDK.Components.BreakpointMap<Uppercase<SDK.Components.ScreenSize>>

const DesignBreakpoints: SDK.Components.BreakpointMap<SDK.Components.ScreenSize> = {
  xs: BreakpointWidth.XS,
  sm: BreakpointWidth.SM,
  md: BreakpointWidth.MD,
  lg: BreakpointWidth.LG,
  xl: BreakpointWidth.XL,
  xxl: BreakpointWidth.XXL,
} as const

const DeviceBreakpoint: SDK.Components.BreakpointMap<SDK.Components.Device> = {
  mobile: BreakpointWidth.SM,
  tablet: BreakpointWidth.MD,
  desktop: BreakpointWidth.LG,
} as const

const Breakpoint: Breakpoint = Object.entries(DesignBreakpoints).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key.toUpperCase()]: value,
  }),
  {} as Breakpoint,
)

const BreakpointMin: Breakpoint = Breakpoint

const BreakpointMax: Breakpoint = Object.entries(Breakpoint).reduce(
  (acc, [key, value]) => ({ ...acc, [key]: value - 1 }),
  Breakpoint,
)

/**
 * Convert width to breakpoint
 * @param {number} width
 */
function getBreakpoint(width: number): SDK.Components.ScreenSize {
  if (width > Breakpoint.XS && width < Breakpoint.MD) return 'sm'
  if (width >= Breakpoint.MD && width < Breakpoint.LG) return 'md'
  if (width >= Breakpoint.LG && width < Breakpoint.XL) return 'lg'
  if (width >= Breakpoint.XL && width < Breakpoint.XXL) return 'xl'
  return width <= Breakpoint.XS ? 'xs' : 'xxl'
}

/**
 * Use this hook to get current breakpoint and device type
 */
const useResponsive: SDK.Components.UseResponsiveHook = () => {
  const isMobile = useMediaQuery({ maxWidth: DeviceBreakpoint.tablet - 1 })
  const isDesktop = useMediaQuery({ minWidth: DeviceBreakpoint.desktop })
  const isTablet = useMediaQuery({ minWidth: DeviceBreakpoint.tablet, maxWidth: DeviceBreakpoint.desktop - 1 })
  const isNative = !!window?.ReactNativeWebView
  const gridSize = isMobile ? 1 : isTablet ? 2 : 3
  const pageSize = isMobile ? 3 : 6
  const currentDevice: SDK.Components.Device = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'
  const gridColSize: SDK.Components.GridColSize = {
    span: 24,
    md: 12,
    xl: 8,
  }
  return {
    device: currentDevice,
    isNative,
    isMobile,
    isDesktop,
    isTablet,
    gridSize,
    pageSize,
    gridColSize,
    largerThan: (size: SDK.Components.ScreenSize) => useMediaQuery({ minWidth: DesignBreakpoints[size] }),
    smallerThan: (size: SDK.Components.ScreenSize) => useMediaQuery({ maxWidth: DesignBreakpoints[size] - 1 }),
    isDevice: (type: SDK.Components.Device) =>
      type === 'mobile' ? isMobile : type === 'tablet' ? isTablet : isDesktop,
    satisfies: ({ breakpoint }) => {
      if (isDesktop) return breakpoint === 'xxl' || breakpoint === 'xl'
      if (isMobile) return breakpoint === 'sm' || breakpoint === 'xs'
      if (isTablet) return breakpoint === 'md' || breakpoint === 'lg'
      return false
    },
  }
}

export { Breakpoint, BreakpointMax, BreakpointMin, DeviceBreakpoint, useDebouncedWindowSize, useResponsive }
